html {
  min-height: 100%;
  font-size: 16px;
  overflow-x: hidden;
  --primary-color: #e93d37;
  --secondary-color: #ffffff;
  --primary-background-color: #262626;
  --primary-text-color: #ffffff;
  --secondary-text-color: #262626;
  --primary-placeholder-color: rgba(38, 38, 38, 0.7);
  --tertiary-color: #cf6679;
  --footer-background-color: #262626;
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 12px;
  }
}

body {
  margin: 0;
  background-color: #eef2f7;
  color: #ffffff;
  color: var(--primary-text-color);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  padding: 0 0.5rem;
}

body,
input,
select,
option {
  font-family: "Roboto", sans-serif;
}

a,
a:visited,
a:focus {
  text-decoration: none;
  color: #ffffff;
  color: var(--secondary-color);
}

.page {
  margin-top: 5rem;
  text-align: center;
  min-height: calc(100vh - 3rem);
}

.button {
  min-height: 3.5rem;
  display: block;
  min-width: 15rem;
  border: none;
  padding: 0 1rem;
  margin: 1rem auto;
  background-color: var(--primary-color);
  color: #ffffff;
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
  color: var(--secondary-text-color);
}

@-webkit-keyframes fadeInAndLower {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

@keyframes fadeInAndLower {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

@-webkit-keyframes fadeInAndRise {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes fadeInAndRise {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  padding: 1rem 2rem;
  font-size: 0.75rem;
  text-align: left;
}

footer > address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.social-links {
  display: flex;
  justify-content: space-between;
}

.social-links > a > svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
}

@media (max-width: 510px) {
  footer {
    flex-direction: column;
  }
  footer > p,
  footer > a,
  footer > address,
  footer > div {
    max-width: 6rem;
    margin: 1rem auto;
  }
}

.nav {
  background-color: var(--primary-background-color);
  top: 0;
  padding: 1rem 2rem;
  position: fixed;
  width: calc(100% - 4rem);
  text-align: left;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}

.mobile-nav {
  display: none;
}

.desktop-nav-links {
  list-style: none;
  display: inline;
}

.nav-home-button {
  cursor: pointer;
  background: none;
  border: none;
}

.MiniLogo {
  height: 2.5rem;
}

.account-link {
  cursor: pointer;
  border: none;
  background: none;
  color: var(--secondary-color);
  font-size: 1rem;
}

.nav-link {
  display: inline;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 0 0.5rem;
}

.desktop-left-content,
.desktop-right-content {
  display: flex;
  align-items: center;
}

@media (max-width: 950px) {
  .mobile-nav {
    display: block;
  }

  .desktop-nav-links {
    display: none;
  }

  .account-link {
    padding: 0;
  }

  .desktop-right-content > .account-link {
    display: none;
  }
}

.nav-contact-us-button {
  min-width: auto;
  margin: 0 0 0 1rem;
  min-height: 2rem;
}

.mobile-nav-menu {
  z-index: 3;
  background-color: var(--primary-background-color);
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-nav-links,
.mobile-nav-links > li > .account-link {
  list-style: none;
  text-align: center;
}

.mobile-nav-links > li {
  margin: 3rem auto 0 auto;
  font-size: 2rem;
}

.mobile-nav-links > li > button {
  font-size: 2rem;
}

.mobile-nav-links__close-button {
  position: absolute;
  border: none;
  background-color: unset;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: bold;
  top: 1rem;
  left: 1rem;
}

.mobile-nav-button {
  background-color: var(--primary-background-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
}

.nav-link {
  background-color: var(--primary-background-color);
  font-size: 1rem;
  margin: 0;
  color: var(--secondary-color);
  border: none;
  cursor: pointer;
}

.account-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.account-profile {
  border-radius: 50%;
  margin-right: 1rem;
}

.AuthenticationLinks {
  right: 1rem;
  width: -webkit-fit-content;
  width: fit-content;
  position: absolute;
  top: 0.75rem;
}

.AuthenticationLinks a {
  color: var(--primary-text-color);
  font-size: 1.25rem;
  margin: 0 0.5rem;
}

.offline-label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: absolute;
  top: 0.75rem;
}

.offline-label > svg {
  height: 1.25rem;
}

.offline-label > span {
  font-size: 1.25rem;
  margin-left: 0.5rem;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-width: 20rem;
  padding: 2.5rem;
  background-color: var(--secondary-color);
  color: var(--secondary-text-color);
  border: none;
  margin: 0;
}

.contact-link {
  color: var(--secondary-text-color) !important;
  text-decoration: underline;
}

#iOS-export-icon {
  width: 1rem;
  margin: 0 0.3rem;
}

dialog button {
  background: none;
  border: none;
  color: var(--primary-text-color);
  height: 2rem;
  width: auto;
  padding: 0.5rem;
  cursor: pointer;
  background-color: var(--secondary-background-color);
  top: 1.5rem;
  right: 2rem;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.dialog-primary-button {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.dialog-close-button {
  position: absolute;
  background-color: unset;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: bold;
}

.dialog-close-button > svg {
  width: 1rem;
}

dialog::-webkit-backdrop {
  /* native */
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
}

dialog::backdrop {
  /* native */
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
}

dialog + .backdrop {
  /* polyfill */
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
}

.contact-us-success-modal > .social-links {
  width: 7rem;
  margin: 0 auto;
}

.ShareButton-header {
  padding: 0 3rem;
}

.ShareButton-link-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.form {
  max-width: 20rem;
  margin: 1rem auto;
  text-align: center;
}

.submission-error-message {
  max-width: 15rem;
  margin: 10px auto;
  background-color: var(--tertiary-color);
  color: var(--secondary-text-color);
  border-radius: 5px;
  padding: 5px 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.submission-error-message__text {
  margin: 0 0 0 10px;
}

.info-icon {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
}

.form-submit {
  color: var(--primary-text-color);
  font-size: 1rem;
}

.form-submit > span > .WhiteLoadingGIF {
  height: 1rem;
}

.field {
  display: inline-block;
  width: 100%;
}

.input {
  color: var(--secondary-text-color);
  border: none;
  border-bottom: 2px solid var(--primary-color);
  height: 2.5rem;
  font-size: 16px;
  padding: 0 1rem;
  margin: 0.5rem 0rem;
  width: calc(100% - 2rem);
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--secondary-text-color);
}

.input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  color: var(--primary-placeholder-color);
  font-size: 1rem;
}

.input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "Roboto", sans-serif;
  color: var(--primary-placeholder-color);
  font-size: 1rem;
}

.field-error {
  color: var(--tertiary-color);
  display: block;
}

#subject-field {
  background-color: var(--secondary-color);
  width: 100%;
}

option:is(:first-of-type) {
  color: var(--primary-placeholder-color);
}

option {
  color: var(--secondary-text-color);
}

.preview {
  width: 20rem;
  margin: 1rem auto;
}

.preview-img {
  width: 100%;
}

.preview-error {
  color: var(--tertiary-color);
}

.login-links, .login-links span {
    margin: 10px auto;
}

.login-links span {
    display: block;
}

.Login > .form {
    max-width: 15rem;
}
.Card {
  background-color: var(--primary-background-color);
  border-radius: 1.25rem;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.Card h2 {
  margin: 0.3rem 0;
}

.Card h3 {
  font-size: 1.3rem;
}

.landing-page {
  display: flex;
  width: 100%;
  height: 100%;
}

.welcome {
  min-height: 108%;
  width: 60%;
  background-color: var(--primary-background-color);
  text-align: left;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.welcome-button,
.cms-button {
  margin: 1rem 0;
  padding: 0;
  min-height: 3rem;
  min-width: 6rem;
}

.cms-button {
  display: inline-block;
  margin-right: 1rem;
}

.welcome > h1 {
  font-size: 2.5rem;
  max-width: 40rem;
}

.welcome > p {
  max-width: 30rem;
}

.landing-img {
  width: 40%;
  height: auto;
}

@media (max-width: 950px) {
  .landing-page {
    background-image: url(/static/media/home-img.e15fc8d6.jpg);
    background-size: cover;
  }

  .welcome {
    min-height: calc(100vh - 5.5rem);
    width: 100%;
    background-color: rgba(38, 38, 38, 0.8);
    justify-content: flex-end;
  }

  .landing-img {
    display: none;
  }
}

.home-section {
  margin: 5rem auto;
  max-width: 70rem;
}

#our-services {
  color: var(--primary-color);
}

.services {
  color: var(--primary-background-color);
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 550px) {
  .services {
    flex-direction: column;
  }
}

.services > div > img {
  height: 2rem;
}

#meet-the-team > h1 {
  color: var(--primary-color);
}

.employee {
  display: flex;
  justify-content: space-around;
}

.employee > p {
  color: var(--primary-background-color);
  text-align: left;
}

.employee > img,
.employee > p {
  width: 350px;
}

@media (max-width: 890px) {
  .employee {
    flex-direction: column;
  }

  .employee > img,
  .employee > p {
    width: 22rem;
    margin: 0 auto 2rem auto;
  }
}

#recent-sales > h1,
#properties-for-lease > h1 {
  text-align: left;
  color: var(--primary-background-color);
}

#testimonials {
  color: var(--secondary-text-color);
}

#testimonials > h1 {
  text-align: left;
}

.cms-carousel {
  max-width: 70rem;
  margin: 0 auto;
}

.slick-prev:before,
.slick-next:before {
  color: var(--secondary-text-color);
}

@media (max-width: 1100px) {
  .home-section {
    max-width: 50rem;
  }
}

@media (max-width: 750px) {
  .home-section {
    max-width: 26rem;
  }
}

.property,
.cms-entity-recentSales,
.cms-entity-propertiesForLease {
  width: 20rem;
  margin: 1rem 0;
}

.property > img {
  width: 100%;
  height: 14rem;
  overflow-y: hidden;
}

.property-info {
  width: 100%;
  text-align: left;
  color: var(--primary-background-color);
}

.property-info > h2 {
  margin: 0.5rem 0;
}

.property-info > small,
.property-stats {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 0.5rem;
}

.property-stats {
  justify-content: space-between;
}

.property-stat,
.property-stat > div {
  display: flex;
}

.property-stat {
  flex-direction: column;
}

.property-stat > div {
  height: 2rem;
  align-items: center;
}

.property-stat > small {
  display: block;
}

.cms-entity-testimonials {
  max-width: 40rem;
  margin: 0 auto;
}

.testimonial {
  text-align: left;
  width: 100%;
  margin: 1rem auto;
}

.testimonial > blockquote {
  font-weight: 200;
  font-style: italic;
  margin: 1rem 0;
  color: var(--secondary-text-color);
  font-size: 2rem;
}

.author {
  color: var(--primary-color);
  font-weight: bold;
}

.title {
  color: var(--secondary-text-color);
}

