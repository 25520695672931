.field {
  display: inline-block;
  width: 100%;
}

.input {
  color: var(--secondary-text-color);
  border: none;
  border-bottom: 2px solid var(--primary-color);
  height: 2.5rem;
  font-size: 16px;
  padding: 0 1rem;
  margin: 0.5rem 0rem;
  width: calc(100% - 2rem);
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--secondary-text-color);
}

.input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "Roboto", sans-serif;
  color: var(--primary-placeholder-color);
  font-size: 1rem;
}

.field-error {
  color: var(--tertiary-color);
  display: block;
}

#subject-field {
  background-color: var(--secondary-color);
  width: 100%;
}

option:is(:first-of-type) {
  color: var(--primary-placeholder-color);
}

option {
  color: var(--secondary-text-color);
}

.preview {
  width: 20rem;
  margin: 1rem auto;
}

.preview-img {
  width: 100%;
}

.preview-error {
  color: var(--tertiary-color);
}
