html {
  min-height: 100%;
  font-size: 16px;
  overflow-x: hidden;
  --primary-color: #e93d37;
  --secondary-color: #ffffff;
  --primary-background-color: #262626;
  --primary-text-color: #ffffff;
  --secondary-text-color: #262626;
  --primary-placeholder-color: rgba(38, 38, 38, 0.7);
  --tertiary-color: #cf6679;
  --footer-background-color: #262626;
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 12px;
  }
}

body {
  margin: 0;
  background-color: #eef2f7;
  color: var(--primary-text-color);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  padding: 0 0.5rem;
}

body,
input,
select,
option {
  font-family: "Roboto", sans-serif;
}

a,
a:visited,
a:focus {
  text-decoration: none;
  color: var(--secondary-color);
}
