.landing-page {
  display: flex;
  width: 100%;
  height: 100%;
}

.welcome {
  min-height: 108%;
  width: 60%;
  background-color: var(--primary-background-color);
  text-align: left;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.welcome-button,
.cms-button {
  margin: 1rem 0;
  padding: 0;
  min-height: 3rem;
  min-width: 6rem;
}

.cms-button {
  display: inline-block;
  margin-right: 1rem;
}

.welcome > h1 {
  font-size: 2.5rem;
  max-width: 40rem;
}

.welcome > p {
  max-width: 30rem;
}

.landing-img {
  width: 40%;
  height: auto;
}

@media (max-width: 950px) {
  .landing-page {
    background-image: url("../img/home-img.jpg");
    background-size: cover;
  }

  .welcome {
    min-height: calc(100vh - 5.5rem);
    width: 100%;
    background-color: rgba(38, 38, 38, 0.8);
    justify-content: flex-end;
  }

  .landing-img {
    display: none;
  }
}

.home-section {
  margin: 5rem auto;
  max-width: 70rem;
}

#our-services {
  color: var(--primary-color);
}

.services {
  color: var(--primary-background-color);
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 550px) {
  .services {
    flex-direction: column;
  }
}

.services > div > img {
  height: 2rem;
}

#meet-the-team > h1 {
  color: var(--primary-color);
}

.employee {
  display: flex;
  justify-content: space-around;
}

.employee > p {
  color: var(--primary-background-color);
  text-align: left;
}

.employee > img,
.employee > p {
  width: 350px;
}

@media (max-width: 890px) {
  .employee {
    flex-direction: column;
  }

  .employee > img,
  .employee > p {
    width: 22rem;
    margin: 0 auto 2rem auto;
  }
}

#recent-sales > h1,
#properties-for-lease > h1 {
  text-align: left;
  color: var(--primary-background-color);
}

#testimonials {
  color: var(--secondary-text-color);
}

#testimonials > h1 {
  text-align: left;
}

.cms-carousel {
  max-width: 70rem;
  margin: 0 auto;
}

.slick-prev:before,
.slick-next:before {
  color: var(--secondary-text-color);
}

@media (max-width: 1100px) {
  .home-section {
    max-width: 50rem;
  }
}

@media (max-width: 750px) {
  .home-section {
    max-width: 26rem;
  }
}
