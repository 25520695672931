.property,
.cms-entity-recentSales,
.cms-entity-propertiesForLease {
  width: 20rem;
  margin: 1rem 0;
}

.property > img {
  width: 100%;
  height: 14rem;
  overflow-y: hidden;
}

.property-info {
  width: 100%;
  text-align: left;
  color: var(--primary-background-color);
}

.property-info > h2 {
  margin: 0.5rem 0;
}

.property-info > small,
.property-stats {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 0.5rem;
}

.property-stats {
  justify-content: space-between;
}

.property-stat,
.property-stat > div {
  display: flex;
}

.property-stat {
  flex-direction: column;
}

.property-stat > div {
  height: 2rem;
  align-items: center;
}

.property-stat > small {
  display: block;
}
