.nav {
  background-color: var(--primary-background-color);
  top: 0;
  padding: 1rem 2rem;
  position: fixed;
  width: calc(100% - 4rem);
  text-align: left;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}

.mobile-nav {
  display: none;
}

.desktop-nav-links {
  list-style: none;
  display: inline;
}

.nav-home-button {
  cursor: pointer;
  background: none;
  border: none;
}

.MiniLogo {
  height: 2.5rem;
}

.account-link {
  cursor: pointer;
  border: none;
  background: none;
  color: var(--secondary-color);
  font-size: 1rem;
}

.nav-link {
  display: inline;
  width: fit-content;
  margin: 0 0.5rem;
}

.desktop-left-content,
.desktop-right-content {
  display: flex;
  align-items: center;
}

@media (max-width: 950px) {
  .mobile-nav {
    display: block;
  }

  .desktop-nav-links {
    display: none;
  }

  .account-link {
    padding: 0;
  }

  .desktop-right-content > .account-link {
    display: none;
  }
}

.nav-contact-us-button {
  min-width: auto;
  margin: 0 0 0 1rem;
  min-height: 2rem;
}

.mobile-nav-menu {
  z-index: 3;
  background-color: var(--primary-background-color);
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-nav-links,
.mobile-nav-links > li > .account-link {
  list-style: none;
  text-align: center;
}

.mobile-nav-links > li {
  margin: 3rem auto 0 auto;
  font-size: 2rem;
}

.mobile-nav-links > li > button {
  font-size: 2rem;
}

.mobile-nav-links__close-button {
  position: absolute;
  border: none;
  background-color: unset;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: bold;
  top: 1rem;
  left: 1rem;
}

.mobile-nav-button {
  background-color: var(--primary-background-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
}

.nav-link {
  background-color: var(--primary-background-color);
  font-size: 1rem;
  margin: 0;
  color: var(--secondary-color);
  border: none;
  cursor: pointer;
}
