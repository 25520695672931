footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  padding: 1rem 2rem;
  font-size: 0.75rem;
  text-align: left;
}

footer > address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.social-links {
  display: flex;
  justify-content: space-between;
}

.social-links > a > svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
}

@media (max-width: 510px) {
  footer {
    flex-direction: column;
  }
  footer > p,
  footer > a,
  footer > address,
  footer > div {
    max-width: 6rem;
    margin: 1rem auto;
  }
}
