.cms-entity-testimonials {
  max-width: 40rem;
  margin: 0 auto;
}

.testimonial {
  text-align: left;
  width: 100%;
  margin: 1rem auto;
}

.testimonial > blockquote {
  font-weight: 200;
  font-style: italic;
  margin: 1rem 0;
  color: var(--secondary-text-color);
  font-size: 2rem;
}

.author {
  color: var(--primary-color);
  font-weight: bold;
}

.title {
  color: var(--secondary-text-color);
}
